import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Bonitaetspruefung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was passiert bei einer Bonitätsprüfung?" showCalc={false} />
            <Article>
                <h2>Die Bonitätsprüfung oder: Wie wird meine Bonität ermittelt?</h2>
                <p>
                    Bei der Bearbeitung deines Kreditantrags schätzt die Bank deine Kreditwürdigkeit in einem
                    Ratingprozess ein. Hierbei wirst du einer bestimmten Bonitätsstufe zugeordnet – je höher diese ist,
                    umso wahrscheinlicher wird man dir die beantragte Kreditsumme gewähren. Im folgenden Beitrag
                    erklären wir dir, was du beachte muss, damit eine solche Bonitätsprüfung zu deinen Gunsten ausfällt.
                </p>
                <hr />

                <h2>Der Haken an der Sache: Kein verbindliches Ratingsystem</h2>
                <p>
                    Der schlechte Nachricht gleich zu Beginn: Es gibt in Österreich allerdings kein allgemein
                    verbindliches Ratingsystem, auf das sich Banken bei der Einstufung ihrer Kund*innen beziehen. Die
                    Kreditinstitute gewichten die einzelnen Parameter auf je unterschiedliche Weise, was deine
                    Selbsteinschätzung als potenzielle Kreditnehmer*in entsprechend erschwert.
                </p>
                <hr />

                <h2>Welche Dokumente werden bei der Bonitätsprüfung benötigt?</h2>
                <p>
                    Damit es überhaupt zu einer Bonitätsprüfung kommt, musst du bereits im Rahmen des Kreditantrags
                    folgende Dokumente vorlegen:
                </p>
                <ul>
                    <li>Ausweis zur Bestätigung der eigenen Identität</li>
                    <li>Nachweis über einen Wohnsitz in Österreich</li>
                    <li>Einkommensnachweis</li>
                    <li>Nachweis eines bestehenden Girokontos</li>
                </ul>
                <p>
                    Im nächsten Schritt kommt es zur Bonitätsprüfung im eigentlichen Sinn. Übrigens: Wenn du dich über
                    die potenziellen Kosten deiner Finanzierung informieren möchtest, ist unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    genau das Richtige.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratungsgespräch vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Welche Kriterien werden bei der Bonitätsprüfung berücksichtigt?</h2>
                <p>
                    Wurden die genannten Nachweise erbracht, so wird die Bonitätsprüfung eingeleitet, in der das
                    Kreditinstitut u.a. folgende Parameter in den Blick nimmt:
                </p>
                <ul>
                    <li>Familiensituation</li>
                    <li>bestehende Kredite und allgemeine Schuldensituation</li>
                    <li>Rücklagen und Vermögenswerte</li>
                    <li>Die Haushaltsrechnung (Einnahmen unter Abzug der Ausgaben)</li>
                    <li>
                        Dienstverhältnis (ist dein Dienstvertrag befristet? Bist du freiberuflich oder als Angestellte*r
                        tätig?)
                    </li>
                </ul>
                <p>
                    Bedauerlicherweise spielt auch dein aktueller Wohnsitz bei der Einschätzung deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    keine geringe Rolle. Dieses Kriterium, das sich deiner Kontrolle größtenteils entzieht, macht die
                    mangelnde Objektivität des Prüfungsverfahrens deutlich – sind derart pauschale Aussagen über ganze
                    Wohngebiete doch zumindest problematisch.
                </p>
                <hr />

                <h2>Was ist ein Bonitätsranking?</h2>
                <p>
                    Je nach Gewichtung der genannten Parameter ergibt sich schließlich eine Kennzahl, die in einem
                    Bonitätsranking verortet wird. Ein solches Ranking könnte beispielsweise so aussehen:
                </p>
                <ul>
                    <li>100-199 – sehr geringes Risiko</li>
                    <li>200-299 – geringes Risiko</li>
                    <li>300-399 – durchschnittliches Risiko</li>
                    <li>400-499 – erhöhtes Risiko</li>
                    <li>500-599 – hohes Risiko</li>
                    <li>600-699 – sehr hohes Risiko</li>
                    <li>Ab 700 – Insolvenz</li>
                </ul>
                <p>
                    Je niedriger die Kennzahl, umso niedriger ist auch das Risiko für die Bank, dir einen Kredit zu
                    gewähren, und umso höher ist zugleich die Wahrscheinlichkeit, einen Kredit zu erhalten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt beraten lassen!
                    </a>
                </p>
                <hr />

                <h2>Die eigenen Möglichkeiten im Blick: Selbstauskunft bei KSV, CRIF, Bisnode & Co</h2>
                <p>
                    Die erste Anlaufstelle, wenn es um Auskünfte zur{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    von Privatpersonen geht, ist der 1870 gegründete Kreditschutzverband – kurz KSV 1870. Dieser hat es
                    sich zur Aufgabe gemacht, personenbezogenen wirtschaftliche Daten – zu Zahlungsverhalten, Liquidität
                    etc. – zu sammeln, die Kreditinstituten im Rahmen einer Bonitätsprüfung zur Verfügung gestellt
                    werden. Doch auch du bist dazu berechtigt, deinen Eintrag einmal pro Jahr kostenlos{" "}
                    <a
                        href="https://www.ksv.at/fuer-privatpersonen/selbstauskunft"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        einzusehen
                    </a>
                    . Das gilt übrigens nicht nur für den KSV: Das Recht auf kostenlose jährliche Einsicht gilt auch für
                    anderen Datenbanken wie CRIF oder Bisnode.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Expertengespräch
                    </a>
                </p>
                <hr />

                <h2>5 Tipps zur Verbesserung deiner Bonität</h2>
                <p>
                    Du fragst dich vielleicht, ob es möglich ist, deine Bonität zu verbessern und dadurch im
                    Ratingprozess eine bessere Bewertung zu erhalten. Die Antwort lautet: Grundsätzlich ja, doch meist
                    nur auf lange Sicht – eine kurzfristige Verbesserung der eigenen Bonität ist in den meisten Fällen
                    leider nicht zu bewerkstelligen. Mit den folgenden 5 Tipps kannst du jedoch sicherstellen, dass sich
                    deine{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    nach und nach verbessert und nachhaltig auf hohem Niveau bleibt:
                </p>
                <ul>
                    <li>
                        Vermeide Zahlungsrückstände und Mahnungen – selbst bei vergleichsweise geringen Ausgaben wie
                        Telefon- oder Internetgebühren
                    </li>
                    <li>
                        Sieh von Ratenzahlungen ab – so manches Kreditinstitut wird das als einen Hinweis auf mangelnde
                        Liquidität auslegen
                    </li>
                    <li>Achte darauf, Schulden immer verlässlich und fristgerecht zu begleichen</li>
                    <li>
                        Biete der Bank Sicherheiten an (etwa eine Hypothek, eine Lebensversicherung oder eine Erbschaft)
                    </li>
                    <li>
                        Stelle den Kreditantrag mit deinem*deiner Partner*in – sofern ihr beide über ein gesichertes
                        Einkommen verfügt, verringert auch ein gemeinsamer Kredit das Risiko eines Zahlungsausfalls
                    </li>
                </ul>
                <p>
                    Du siehst also: Ein wenig lässt sich bei deiner Bonität schon nachhelfen. Wenn du die genannten 5
                    Punkte berücksichtigst, bist du auf einem guten Weg zu einer positiven Bewertung im Ratingprozess
                    deines Kreditinstituts.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Beratungsgespräch mit einer Finanzexpert*in von miracl
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"bonitaetspruefung"}></BreadcrumbList>
            <ArticleStructuredData page={"bonitaetspruefung"} heading={"Was passiert bei einer Bonitätsprüfung?"} />
        </Layout>
    );
};

export default Bonitaetspruefung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.bonitaetspruefung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
